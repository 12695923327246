<template>
  <div class="page has-background-blue with-title">
    <div class="columns is-multiline is-mobile">
      <div
        class="column pb-0 is-gapless is-12 is-sticky has-text-centered"
        style="top: 150px;"
      >
        <img
          class="cover-image"
          :src="getImagePath(section_cover)"
          :alt="section_title"
        >
      </div>
      <div class="column is-12 has-background-white pt-0 pb-0">
        <div class="main-title px-5 py-3 mb-1 has-background-white">
          <h4 class="subtitle has-text-weight-bold">
            {{ section_title }}
          </h4>
          <b-switch
            v-if="playlist.length > 0"
            v-model="orderingItems"
            size="is-small"
          >
            <!--<b-icon
              pack="fas"
              icon="sort"
            />-->
            Ordenar
          </b-switch>
        </div>
        <div
          v-if="playlist.length === 0"
          class="no-songs"
        >
          Ahora mismo no tienes canciones, accede a
          <b-button
            class="mx-2"
            type="is-primary"
            size="is-small"
            @click="openAlbums"
          >
            Reproductor
          </b-button>
          y agrega tus canciones favoritas.
        </div>
        <draggable
          :options="{disabled : !orderingItems}"
          ghost-class="ghost"
          @end="updatePlaylist"
        >
          <transition-group>
            <album-song-list-item
              v-for="song in list"
              :key="song.id"
              :data="song"
              :in-playlist="true"
              :is-selected="isCurrentSong(song)"
              :disabled="song.blob === null"
              :downloading="song.blob === null"
              @click="selectTrack"
              @remove-from-playlist="removeFromPlaylist"
            >
              <template #image>
                <img
                  class="disc"
                  :src="getImagePath(song.album_cover)"
                  :alt="song.album_title"
                >
              </template>
            </album-song-list-item>
          </transition-group>
        </draggable>
        <div
          v-if="playlist.length > 0"
          class="player-controls"
        >
          <audio-player-play-list
            ref="playlistPlayer"
            tempo-variant="general"
            :playlist="playlist"
            :auto-play="true"
            :play-list-mode="playListMode"
            :selected-index="selectedTrackIndex"
            :has-tempo="hasTempo"
            @change-track="changeTrack"
            @change-mode="changeMode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from "@/utils/image"
import AlbumSongListItem from "@/components/lists/AlbumSongListItem"
import AudioPlayerPlayList from "@/components/audio/AudioPlayerPlayList"
import draggable from 'vuedraggable'

export default {
  name: 'UserPlayListDetal',
  components: {AudioPlayerPlayList, AlbumSongListItem, draggable},
  title: 'Reproductor local',
  data: function () {
    return {
      section_title: 'Reproductor local',
      section_cover: '/shared/CARTEL%20NUEVO%20MUSICAEDUCA%20HORIZONTAL_1.jpg',
      list: null,
      selectedTrackId: null,
      selectedTrackIndex: null,
      hasTempo: true,
      playListMode: 'continuous',
      orderingItems: false
    }
  },
  computed: {
    playlist() {
      return this.$store.state.playlist
    },
    emptyResult() {
      if (!this.albums) {
        return false
      }

      return this.albums.length === 0
    },
    userToken() {
      return this.$store.state.token
    },
    queue() {
      return this.$store.state.downloadList
    }
  },
  watch: {
    userToken() {
      this.refreshPlayList()
    },
    userLevel() {
      return this.$store.state.user?.level || 0
    },
    queue() {
      this.refreshPlayList()
    },
    playlist(value) {
      this.list = value
    }
  },
  async beforeMount() {
    if (this.isAuth && this.userLevel >= 20) {
      this.playListMode = 'single'
    }

    this.setPageTitle(this.section_title)
    await this.refreshPlayList()
  },
  methods: {
    selectTrack(track) {
      const foundIndex = this.playlist.findIndex(item => item.id === track.id)

      this.selectedTrackId = track.id
      this.selectedTrackIndex = foundIndex > -1 ? foundIndex : null
    },
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    isCurrentSong(song) {
      return song.id === this.selectedTrackId
    },
    changeTrack(event) {
      let nextAvailableTrack = null

      if (event.type === 'next') {
        nextAvailableTrack = this.getNextAvailableTrackFromIndex(event.index)
      }

      if (event.type === 'prev') {
        nextAvailableTrack = this.getPrevAvailableTrackFromIndex(event.index)
      }

      if (nextAvailableTrack) {
        const song = this.playlist[nextAvailableTrack]
        this.selectedTrackId = song.id
        this.selectedTrackIndex = nextAvailableTrack
      }
    },
    getPrevAvailableTrackFromIndex(index) {
      //busca las canciones a partir de la actual

      let foundIndex = null

      for (let cindex = index; cindex >= 0; cindex--) {
        if (this.playlist[cindex].blob !== null) {
          foundIndex = cindex
          break
        }
      }

      if (!foundIndex) {
        for (let cindex = this.playlist.length - 1; cindex > index; cindex--) {
          if (this.playlist[cindex].blob !== null) {
            foundIndex = cindex
            break
          }
        }
      }

      return foundIndex
    },
    getNextAvailableTrackFromIndex(index) {
      //busca las canciones a partir de la actual
      let foundIndex = this.playlist.findIndex((item, cindex) => {
        return cindex >= index && item.blob !== null
      })

      if (foundIndex === -1) {
        this.playlist.findIndex((item, cindex) => {
          return cindex < index && item.blob !== null
        })
      }

      if (foundIndex === -1) {
        return null
      }

      return foundIndex
    },
    changeMode(mode) {
      if (!this.isAuth && mode !== 'continuous') {
        this.$msg.show('Solo los usuarios registrados pueden cambiar el modo de reproducción')
        return
      }
      this.playListMode = mode
    },
    async removeFromPlaylist(song) {
      try {
        await this.$db.remove(process.env.VUE_APP_PLAYLIST_STORE, song.id)

        this.$msg.success(`<b>${song.title}</b> se ha quitado del reproductor local`)
        await this.refreshPlayList()
      } catch (e) {
        console.error('-- error al borrar --')
        console.error(e)
        this.$msg.error('No se ha podido eliminar la canción del reproductor local, inténtalo más tarde')
      }

    },
    async openAlbums() {
      try {
        await this.$router.push({name: 'albums'})
      } catch (e) {
        console.error(e)
      }
    },
    async refreshPlayList() {
      try {
        await this.$store.dispatch('loadPlaylist', true)
      } catch (e) {
        console.error(e)
      }
    },
    async updatePlaylist(event) {
      const oldIndex = event.oldIndex
      const newIndex = event.newIndex
      const movedItem = this.list.splice(oldIndex, 1)[0]

      this.list.splice(newIndex, 0, movedItem)
      this.list.forEach((song, index) => {
        this.$db.update(process.env.VUE_APP_PLAYLIST_STORE, {id: song.id, position: index})
      })
    }
  }
}
</script>

<style scoped lang="scss">
.main-title {
  position: sticky;
  top: 0;
  border-bottom: 2px solid #709dc3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.cover-image {
  max-width: 300px;
  display: block;
  margin: 0 auto;
}

.ghost {
  background-color: white;
}

.player-controls {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -2px;
  background-color: white;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.no-songs {
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}
</style>
