<template>
  <div
    v-if="data"
    class="song-item py-1"
  >
    <div class="content-col py-1">
      <h5 class="is-6 subtitle mb-1 has-text-weight-bold is-uppercase">
        {{ trackNumber(data.track) }} - {{ data.title }}
      </h5>
      <div>
        Autor: {{ data.author }}
      </div>
      <div>
        Año: {{ data.year }}
      </div>
      <div>
        <a
          v-if="data.link"
          class="is-small btn button is-info"
          target="_blank"
          :href="data.link"
        >
          Más información
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AlbumSongInfoItem",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  methods: {
    trackNumber(track){
      const trackNumber = '0'+track

      return 'Pista '+trackNumber.substring((trackNumber.length-2))
    }
  }
}
</script>

<style scoped lang="scss">
.song-item {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 2px solid #709dc3;
  cursor: pointer;

  &.selected {
    background-color: #b8cee1;
  }

  &.disabled {
    cursor: inherit;

    .image-col{
      opacity: .6;
    }

    .content-col {
        opacity: .6;

      .song-actions {
        a:hover{
          cursor: default;
          color: #2776B8;
        }
      }
    }
  }

  .image-col {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
    width: 60px;
    transition: opacity 0.5s ease-in-out;

    img {
      width: 45px;
      height: 45px;
      object-fit: contain;
    }
  }

  .content-col {
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    transition: opacity 0.5s ease-in-out;

    .song-actions {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      .download-song-icon {
        height: 20px;
      }
    }
  }

  .right-item{
    margin-left: auto;
    margin-right: 1rem;
    display:flex;
    align-items: center;

    .icon {
      color: #2776B8;
      -webkit-animation: spin 3s linear infinite;
      -moz-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
