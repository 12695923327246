<template>
  <div
    v-if="data"
    class="song-item py-1"
    :class="itemClasses"
    @click="handleClick"
  >
    <div class="image-col">
      <slot name="image">
        <img
          class="disc"
          :src="discIcon"
          alt="Dificultad"
        >
      </slot>
    </div>
    <div class="content-col py-1">
      <h5 class="is-6 subtitle mb-1">
        {{ data.title }}
      </h5>
      <div class="song-actions">
        <div>
          <a
            v-if="hasLyric"
            href="#"
            @click.stop="openLyric"
          >
            <b-icon
              icon="music"
              size="is-small"
            />
          </a>
        </div>
        <a
          class="mr-2"
          href="#"
          @click.stop="toggleStoreSong"
        >
          <img
            class="download-song-icon"
            :src="downloadSongIcon"
            alt="download song"
          >
        </a>
      </div>
    </div>
    <div
      v-if="downloading"
      class="right-item"
    >
      <b-icon
        icon="sync"
        size="is-small"
      />
    </div>
  </div>
</template>

<script>
import blueDisc from '@/assets/img/song-levels/azul.png'
import redDisc from '@/assets/img/song-levels/rojo.png'
import orangeDisc from '@/assets/img/song-levels/naranja.png'
import greenDisc from '@/assets/img/song-levels/verde.png'
import downloadSongIcon from '@/assets/img/controls/download-audio-light.png'
import downloadSongOkIcon from '@/assets/img/controls/download-audio-light-ok.png'
import LyricModal from "@/components/modals/LyricModal";

export default {
  name: "AlbumSongListItem",
  props: {
    data: {
      type: Object,
      default: null
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    inPlaylist: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    downloading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasLyric() {
      if (!this.data) {
        return false
      }

      return !!this.data.lyric_text
    },
    downloadSongIcon() {
      if (this.inPlaylist === true) {
        return downloadSongOkIcon
      }

      return downloadSongIcon
    },
    discIcon() {
      if (!this.data) {
        return null
      }

      if (this.data.level === 'azul') {
        return blueDisc
      }

      if (this.data.level === 'rojo') {
        return redDisc
      }

      if (this.data.level === 'naranja') {
        return orangeDisc
      }

      if (this.data.level === 'verde') {
        return greenDisc
      }

      return null
    },
    itemClasses() {
      const classes = []
      if (this.isSelected) {
        classes.push('selected')
      }

      if (this.disabled) {
        classes.push('disabled')
      }

      return classes.join(' ')
    }
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        return
      }
      console.warn('handle click')
      this.$emit('click', this.data)
    },
    openLyric() {
      if (this.disabled) {
        return
      }

      const config = {
        component: LyricModal,
        props: {song: this.data},
        hasModalCard: true,
        fullScreen: true
      }
      this.$buefy.modal.open(config)
    },
    async toggleStoreSong() {
      if (this.disabled) {
        return
      }
      if (!this.inPlaylist) {
        this.$emit('add-to-playlist', this.data)
        return
      }

      this.$buefy.dialog.confirm({
        message: 'Vas a borrar del reproductor local la canción <b>' + this.data.title + '</b>',
        cancelText: 'Cancelar',
        confirmText: 'Borrar',
        type: 'is-danger',
        onConfirm: () => {
          this.$emit('remove-from-playlist', this.data)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.song-item {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 2px solid #709dc3;
  cursor: pointer;

  &.selected {
    background-color: #b8cee1;
  }

  &.disabled {
    cursor: inherit;

    .image-col {
      opacity: .6;
    }

    .content-col {
      opacity: .6;

      .song-actions {
        a:hover {
          cursor: default;
          color: #2776B8;
        }
      }
    }
  }

  .image-col {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
    width: 60px;
    transition: opacity 0.5s ease-in-out;

    img {
      width: 45px;
      height: 45px;
      object-fit: contain;
    }
  }

  .content-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    transition: opacity 0.5s ease-in-out;
    padding-right: 1rem;

    .song-actions {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      .download-song-icon {
        height: 20px;
      }
    }
  }

  .right-item {
    margin-left: auto;
    margin-right: 1rem;
    display: flex;
    align-items: center;

    .icon {
      color: #2776B8;
      -webkit-animation: spin 3s linear infinite;
      -moz-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
