<template>
  <div
    class="modal-card"
    style="width: auto"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">
        Lírica - {{ song.title }}
      </p>
      <!--<button
        type="button"
        class="delete"
        @click="$emit('close')"
      />-->
    </header>
    <section class="modal-card-body">
      <div v-html="song.lyric_text" />
    </section>
    <footer class="modal-card-foot is-flex is-justify-content-space-between">
      <b-button
        label="Cerrar"
        type="is-primary"
        @click="$emit('close')"
      />
      <b-button
        type="is-danger"
        label="Descargar PDF"
        icon="filepdf"
        @click="downloadLyric"
      />
    </footer>
  </div>
</template>

<script>
import slugify from 'slugify'

export default {
  name: "LyricModal",
  props: {
    song: {
      type: Object,
      default: null
    }
  },
  methods: {
    async downloadLyric() {
      try {
        const filename = `${slugify(this.song.title, {lower: true})}.pdf`
        const file = await this.$api.album.getLyricPdf(this.song.id)

        const url = window.URL.createObjectURL(new Blob([file.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      } catch (e) {
        this.$msg.error('No se ha podido descargar el pdf con la lírica')
      }
    }
  }
}
</script>

<style lang="scss">
.modal-close {
  background-color: lightgrey;
}
</style>
