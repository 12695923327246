<template>
  <div
    v-if="data"
    class="page has-background-blue with-title"
  >
    <div class="columns is-multiline is-mobile">
      <div
        class="column pb-0 is-gapless is-12 is-sticky has-text-centered"
        style="top: 150px;"
      >
        <div class="album-image-container">
          <img
            class="cover-image"
            :src="getImagePath(data.cover)"
            :alt="data.title"
          >
          <a
            v-if="data.link"
            :href="data.link"
            class="link-btn"
            target="_blank"
          >
            <img
              :src="linkBtnIcon"
              alt="Abrir enlace"
            ></a>
          <a
            href="#"
            class="more-info-btn"
            @click.prevent="openAlbumInfo"
          >
            <img
              :src="infoBtnIcon"
              alt="Más información"
            ></a>
        </div>
      </div>
      <div class="column is-12 has-background-white pt-0 pb-0">
        <div class="main-title px-5 py-3 mb-1 has-background-white">
          <h4 class="subtitle has-text-weight-bold">
            {{ data.title }}
          </h4>
        </div>
        <album-song-list-item
          v-for="song in data.songs"
          :key="song.id"
          :data="song"
          :is-selected="isCurrentSong(song)"
          :in-playlist="inPlaylist(song)"
          @click="selectTrack"
          @add-to-playlist="addToPlaylist"
          @remove-from-playlist="removeFromPlaylist"
        />
        <div class="player-controls">
          <audio-player-play-list
            ref="playlistPlayer"
            tempo-variant="general"
            :playlist="data.songs"
            :auto-play="true"
            :play-list-mode="playListMode"
            :selected-index="selectedTrackIndex"
            :has-tempo="hasTempo"
            @change-track="changeTrack"
            @change-mode="changeMode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";
import AlbumSongListItem from "@/components/lists/AlbumSongListItem";
import AudioPlayerPlayList from "@/components/audio/AudioPlayerPlayList";
import infoBtn from '@/assets/img/btn/info-btn.png'
import linkBtn from '@/assets/img/btn/link-btn.png'
import AlbumInfoModal from "@/components/modals/AlbumInfoModal";

export default {
  name: 'AlbumDetail',
  components: {AudioPlayerPlayList, AlbumSongListItem},
  title: 'Reproductor',
  data: function () {
    return {
      section_title: 'Reproductor',
      infoBtnIcon: infoBtn,
      linkBtnIcon: linkBtn,
      data: null,
      selectedTrackId: null,
      selectedTrackIndex: null,
      hasTempo: true,
      //playListMode: 'single'
      playListMode: 'continuous'
    }
  },
  computed: {
    currentUserId() {
      return this.$store.state.user?.user_id
    },
    userToken() {
      return this.$store.state.token
    },
    userLevel() {
      return this.$store.state.user?.level || 0
    },
    playlist() {
      return this.$store.state.playlist
    }
  },
  watch: {
    userToken() {
      this.loadContent()
    }
  },
  async beforeMount() {
    if (this.isAuth && this.userLevel >= 20) {
      this.playListMode = 'single'
    }

    await this.loadContent()
  },
  methods: {
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')
        const id = this.$route.params.id
        const album = await this.$api.album.getItemById(id)
        album.songs = album.songs.map(song => {
          song.album_id = album.id
          song.album_title = album.title
          song.album_cover = album.cover
          return song
        })
        this.data = album

        this.section_title = this.data.title
        this.setPageTitle(this.data.title)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    selectTrack(track) {
      console.info("play/pause")
      const foundIndex = this.data.songs.findIndex(item => item.id === track.id)

      this.selectedTrackId = track.id
      this.selectedTrackIndex = foundIndex > -1 ? foundIndex : null
    },
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    isCurrentSong(song) {
      return song.id === this.selectedTrackId
    },
    inPlaylist(song) {
      return this.playlist.findIndex(item => item.id === song.id) > -1
    },
    changeTrack(event) {
      this.selectedTrackId = event.item.id
      this.selectedTrackIndex = event.index
    },
    changeMode(mode) {
      if (!this.isAuth && mode !== 'continuous') {
        this.$msg.show('Solo los usuarios registrados pueden cambiar el modo de reproducción')
        return
      }
      this.playListMode = mode
    },
    getAudioPath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    async addToPlaylist(song) {
      try {
        if (!this.isAuth) {
          this.$msg.show('Solo los usuarios registrados pueden usar el reproductor local')
          return
        }
        const audioUrl = this.getAudioPath(song.audio)
        song.user_id = this.currentUserId || null
        song.blob = null
        song.position = this.$store.state.playlist.length

        await this.$db.add(process.env.VUE_APP_PLAYLIST_STORE, song)

        const downloadItem = {id: song.id, title: song.title, src: audioUrl}
        this.$store.commit('addBlobToQueue', downloadItem)

        this.$msg.success(`<b>${song.title}</b> se ha añadido al reproductor local`)
        //const songBlob = await this.$api.album.getSongFile(audioUrl)

        await this.$store.dispatch('loadPlaylist')
      } catch (e) {
        console.error('-- error al guardar --')
        console.error(e)
        this.$msg.error('No se ha podido guardar la canción en el reproductor local, inténtalo más tarde')
      }
    },
    async removeFromPlaylist(song) {
      try {
        await this.$db.remove(process.env.VUE_APP_PLAYLIST_STORE, song.id)
        const audioUrl = this.getAudioPath(song.audio)
        const downloadItem = {id: song.id, title: song.title, src: audioUrl}
        this.$store.commit('removeBlobFromQueue', downloadItem)

        this.$msg.success(`<b>${song.title}</b> se ha quitado del reproductor local`)

        await this.$store.dispatch('loadPlaylist')
      } catch (e) {
        console.error('-- error al borrar --')
        console.error(e)
        this.$msg.error('No se ha podido eliminar la canción del reproductor local, inténtalo más tarde')
      }
    },
    openAlbumInfo() {
      const config = {
        component: AlbumInfoModal,
        props: {album: this.data},
        hasModalCard: true,
        fullScreen: true
      }
      this.$buefy.modal.open(config)
    }
  }
}
</script>

<style scoped lang="scss">
.main-title {
  position: sticky;
  top: 0;
  border-bottom: 2px solid #709dc3;
}

.cover-image {
  max-width: 300px;
}

.player-controls {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -2px;
  background-color: white;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.album-image-container{
  position: relative;

  .link-btn{
    position: absolute;
    bottom: 56px;
    right: 8px;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
  }
  .more-info-btn{
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
  }
}
</style>
