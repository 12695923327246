<template>
  <div
    class="modal-card"
    style="width: auto"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ album.title }}
      </p>
      <button
        type="button"
        class="delete"
        @click="$emit('close')"
      />
    </header>
    <section class="modal-card-body">
      <album-song-info-item
        v-for="song in album.songs"
        :key="song.id"
        :data="song"
      />
    </section>
    <footer class="modal-card-foot is-flex is-justify-content-space-between">
      <b-button
        label="Cerrar"
        type="is-primary"
        @click="$emit('close')"
      />
    </footer>
  </div>
</template>

<script>
import AlbumSongInfoItem from "@/components/lists/AlbumSongInfoItem";
export default {
  name: "AlbumInfoModal",
  components: {AlbumSongInfoItem},
  props: {
    album: {
      type: Object,
      default: null
    }
  },
  methods: {

  }
}
</script>
