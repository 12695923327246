<template>
  <div v-if="albums" class="page has-background-blue with-title">
    <h1 class="page-title has-text-weight-bold has-text-centered is-size-5">{{ section_title }}</h1>
    <div class="container p-4">
      <div class="columns is-multiline is-mobile">
        <GenericMosaicItem v-for="item in albums" :data="item" :key="item.id" @click="openContent(item)" />
        <div class="column is-12-mobile is-6-tablet is-offset-3-tablet" v-if="emptyResult">
          <div class="no-results">
            No se han encontrado resultados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenericMosaicItem from "@/components/lists/GenericMosaicItem";

export default {
  components: {GenericMosaicItem},
  title: 'Reproductor',
  name: 'AlbumList',
  data: function () {
    return {
      section_title: 'Reproductor',
      albums: null
    }
  },
  computed: {
    emptyResult() {
      if (!this.albums) {
        return false
      }

      return this.albums.length === 0
    },
    userToken() {
      return this.$store.state.token
    }
  },
  methods: {
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')

        this.albums = await this.$api.album.getList()

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    openContent(item) {
      const albumId = item.id
      this.$router.push(`/albums/${albumId}`)
    }
  },
  beforeMount() {
    this.loadContent()
  },
  watch: {
    userToken() {
      this.loadContent()
    }
  }
}
</script>
